import { Button } from "@components/controls/Button"
import { ErrorPanel } from "@components/layout/non-ideal-states"
import { useAuth } from "@features/accounts/hooks/useAuth"
import * as Sentry from "@sentry/react"
import { useRouterEvents } from "eddev/routing"
import { errorHandling } from "eddev/runtime"
import { useEffect } from "react"
import { proxy, useSnapshot } from "valtio"

const sentErrors = proxy({
  ids: [] as string[],
})

const ABOUT_TO_SEND = new Set<{
  event: Sentry.ErrorEvent
  hint: Sentry.EventHint
  ignore: boolean
}>()

const client = Sentry.isInitialized()
  ? Sentry.getClient()
  : Sentry.init({
      dsn: "https://3a5b1b7d82f8eb72289bcc8047e4f2b4@o152606.ingest.us.sentry.io/4508214676881408",
      environment: env.dev ? "development" : "production",
      // defaultIntegrations
      integrations: [
        Sentry.browserTracingIntegration({
          instrumentNavigation: false,
          instrumentPageLoad: false,
        }),
        Sentry.replayIntegration(),
      ],
      async beforeSend(event, hint) {
        if (env.dev) return null
        if (hint.originalException && hint.originalException instanceof Error) {
          /**
           * Workaround to prevent duplicate errors from being sent to Sentry, when they're handled by ErrorBoundary
           */
          const msg = {
            event,
            hint,
            ignore: false,
          }
          ABOUT_TO_SEND.add(msg)
          await new Promise((resolve) => setTimeout(resolve, 1000))
          ABOUT_TO_SEND.delete(msg)
          if (msg.ignore) return null
        }
        console.log("Event", event)
        if (event.event_id) {
          sentErrors.ids.push(event.event_id)
        }
        return event
      },
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/agda\.com\.au/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })!

if (!env.client) {
  Sentry.setTag("ssr", "true")
}
if (env.admin) {
  Sentry.setTag("admin", "true")
}

let pageLoadSpan =
  env.client && client
    ? Sentry.startBrowserTracingPageLoadSpan(client, {
        name: window.location.pathname,
        attributes: {
          [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "url",
        },
      })
    : undefined

function maybeIgnore(message: string) {
  for (let msg of ABOUT_TO_SEND.values()) {
    console.log("Ignore?", msg.hint.originalException, message)
    if (msg.hint.originalException instanceof Error) {
      if (msg.hint.originalException.message === message) {
        console.log("Ignoring!")
        msg.ignore = true
      }
    }
  }
}

errorHandling.onReactError = (event) => {
  // Check to see if the error was already sent to Sentry
  if (event.type === "block") {
    maybeIgnore(event.error.message)
    Sentry.captureException(event.error, {
      extra: {
        componentStack: event.componentStack,
      },
      tags: {
        type: "block",
        blockName: (event.block.slug as string) ?? (event.block.blockName as string),
      },
    })
  } else if (event.type === "route") {
    maybeIgnore(event.error.message)
    Sentry.captureException(event.error, {
      tags: {
        type: "route",
        view: event.route.view,
      },
    })
  }
}

errorHandling.renderFallback = (error, defaultFallback) => {
  if (error.type === "route") {
    return (
      <ErrorPanel
        title="Oops..."
        message={
          <div className="el-p:mb-2">
            <p>Something went wrong while loading this page.</p>
            <ErrorIDs />
          </div>
        }
        button={<Button onClick={() => document.location.reload()}>Reload Page</Button>}
      />
    )
  }
  return defaultFallback
}

export function SentryTracing() {
  const auth = useAuth()

  // Add user information to Sentry
  useEffect(() => {
    if (auth.user) {
      Sentry.setUser({
        email: auth.user.email,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [auth.user])

  useRouterEvents((event) => {
    if (event.type === "navigate:will-change" && client) {
      const route = event.loadedRoute
      if (pageLoadSpan) {
        pageLoadSpan.updateName(route.uri)
        pageLoadSpan.setAttribute(Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE, "url")
        pageLoadSpan = undefined
      } else {
        Sentry.startBrowserTracingNavigationSpan(client, {
          op: "navigation",
          name: route.uri,
          attributes: {
            [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "url",
          },
        })
      }
    }
  })

  return null
}

function ErrorIDs() {
  const ids = useSnapshot(sentErrors.ids)

  if (ids.length) {
    return <p>Reference: {ids.join(", ")}</p>
  }

  return null
}
